// 引入vuex
import Vue from 'vue'
import Vuex from 'vuex'
import saas from '@/utils/api/saas'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // 用户信息
        user: JSON.parse(window.localStorage.getItem("userInfo")),
        // 富文本配置文件
        editor: [
            [
                "bold", "italic", "underline", "strike", "link",
                { "color": [] },
                { "background": [] },
                { "font": [] }, { "size": [] }
            ],
            [{ "header": 1 }, { "header": 2 }],
            [{ "align": [] }],
            ["image", "video"]
        ],
        // 发布作品页是否缓存
        isRetain: false,
        simpleHeaderFlag: false,
    },
    mutations: {
        // 修改用户信息
        changeUser(state, num) {
            state.user = num
            saas.ssoCheckReq({
                token: 'bearer ' + localStorage.getItem("access_token")
            })
        },
        // 修改缓存状态
        changeRetain(state, num) {
            state.isRetain = num
        },
        // 修改缓存状态
        CHANGE_SIMPLEHEADER(state, flag) {
            state.simpleHeaderFlag = flag
        },
    }
})
