export default {
    name: "defaultIcon",
    data() {
        return {

        }
    },
    props: [
        "type"
    ],
    mounted () {

    },
    methods: {

    }
}
