// 高校我的

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const schoolMine = {
    // 高校资料
    getInfo() {
        return axios.get(`${base.rq}api/platform/college/getEditCollegeDetail`);
    },
    // 更新首页信息
    updateInfo(params) {
        return axios.post(`${base.rq}api/platform/college/editCollegeDetail`, params);
    },
    // 获取首页展示信息
    indexData(params) {
        return axios.get(`${base.rq}api/platform/college/getFrontPageDetail`, {
            params: params
        });
    },
    // 活动列表
    activity(params) {
        return axios.get(`${base.rq}api/product/collegeCampaign/getCollegeManageCampaign`, {
            params: params
        });
    },
    // 取消活动
    cancelActive(params) {
        return axios.post(`${base.rq}api/product/collegeCampaign/cancelCampaign`, params);
    },
    // 再次发起活动
    againActive(params) {
        return axios.post(`${base.rq}api/product/collegeCampaign/publishAgain`, params);
    },
    // 实验室/团队列表
    Laboratory(params) {
        return axios.get(`${base.rq}api/platform/college/getLabManageList`, {
            params: params
        });
    },
    // 实验室/团队排序
    LaboratorySort(params) {
        return axios.post(`${base.rq}api/platform/college/sortLab`, params);
    },
    // 实验室/团队详情
    laboratoryInfo(params) {
        return axios.get(`${base.rq}api/platform/college/updateCollegeLabInfo`, {
            params: params
        });
    },
    // 添加实验室/团队
    addLaboratory(params) {
        return axios.post(`${base.rq}api/platform/college/createLab`, params);
    },
    // 编辑实验室/团队
    editLaboratory(params) {
        return axios.post(`${base.rq}api/platform/college/updateCollegeLab`, params);
    },
    // 删除实验室/团队
    removeLaboratory(params) {
        return axios.post(`${base.rq}api/platform/college/deleteLab`, params);
    },
    // 公告列表
    notice(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeManageNoticeList`, {
            params: params
        });
    },
    // 新增公告
    addNotice(params) {
        return axios.post(`${base.rq}api/platform/college/publishNotice`, params);
    },
    // 公告信息
    getNoticeInfo(params) {
        return axios.get(`${base.rq}api/platform/college/editCollegeNoticeDetail`, {
            params: params
        });
    },
    // 编辑公告
    editNotice(params) {
        return axios.post(`${base.rq}api/platform/college/editCollegeNotice`, params);
    },
    // 删除公告
    removeNotice(params) {
        return axios.post(`${base.rq}api/platform/college/deleteCollegeNotice`, params);
    },
    // 成员列表
    member(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeManageMember`, {
            params: params
        });
    },
    // 搜索成员列表
    memberSearch(params) {
        return axios.get(`${base.rq}api/platform/college/searchCollegeMember`, {
            params: params
        });
    },
    // 称谓列表
    memberTitle(params) {
        return axios.get(`${base.rq}api/platform/college/getTitleList`, {
            params: params
        });
    },
    // 获取团队/实验室列表
    collegeLabDrop(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeLabDrop`, {
            params: params
        });
    },
    // 获取账号
    getDesignerByPhone(params) {
        return axios.get(`${base.rq}api/platform/college/getDesignerByPhone`, {
            params: params
        });
    },
    // 新增成员
    addMember(params) {
        return axios.post(`${base.rq}api/platform/college/addMember`, params);
    },
    // 成员信息
    getMemberInfo(params) {
        return axios.get(`${base.rq}api/platform/college/getMemberInfo`, {
            params: params
        });
    },
    // 更新成员信息
    editMember(params) {
        return axios.post(`${base.rq}api/platform/college/editMember`, params);
    },
    // 删除成员
    removeMember(params) {
        return axios.post(`${base.rq}api/platform/college/removeCollegeMember`, params);
    },
    // 获取加入的高校信息
    getCollegeInfo(params) {
        return axios.get(`${base.rq}api/platform/college/getDesignerCollegeDetail`, {
            params: params
        });
    },
    // 个人活动列表
    getDesignerCampaignListReq(params) {
        return axios.get(`${base.rq}api/product/campaign/getDesignerCampaignList`, {
            params: params
        });
    },
};

export default schoolMine;
