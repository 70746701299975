import memberOperation from '../../pages/design/mineColleges/menu/memberOperation/index.vue'
export default {
    name: "selectMember",
    components: {
        memberOperation
    },
    data() {
        return {
            modal: false,
            success: null,
            // 弹窗标题
            title: "",
            // 搜索内容
            search: "",
            // 表格加载状态
            loading: false,
            // 表格配置
            tableColumns: [
                {
                    title: "选择",
                    width: 40,
                    align: 'center',
                    render:(h,params)=>{
                        return h('div',[
                            h('Checkbox',{
                                props:{
                                    value: params.row.checkBox
                                },
                                on:{
                                    'on-change':(e)=>{
                                        // 判断单选
                                        if (!this.select) {
                                            this.tableData.forEach((e)=>{
                                                //消所有对象的勾选，checkBox设置为false
                                                this.$set(e,'checkBox',false)
                                            });
                                        }
                                        this.tableData[params.index].checkBox = e;
                                    }
                                }
                            })
                        ])
                    }
                },{
                    title: "头像",
                    width: 80,
                    align: 'center',
                    slot: "avatar"
                },{
                    title: "姓名",
                    slot: "name"
                },{
                    title: "角色",
                    width: 80,
                    slot: "type",
                    align: 'center'
                },{
                    title: "职称",
                    width: 120,
                    slot: "title"
                }
            ],
            // 表单数据
            tableData: [],
            // 是否支持多选
            select: false,
            addMemberModal: false,
            // 分页
            page: 1,
            pageSize: 10,
            count: 0,
            // 数据状态
            checkBox:{},
            // 最多选中
            length: null,
            lengthItem: 0,
            // 超出选择提示
            lengthModal: false
        }
    },
    computed: {
        // 滚动加载禁止
        noMore () {
            return this.page >= Math.ceil(this.count / this.pageSize);
        },
    },
    methods: {
        // 获取携带的参数
        getParamsItem(options) {
            this.modal = true;
            this.title = options.title;
            this.select = options.select;
            // 重置选中的数据
            this.checkBox = {};
            if (options.checkBox) {
                // 进行参拷贝，防止修改原数据
                options.checkBox = JSON.parse(JSON.stringify(options.checkBox));
                options.checkBox.forEach((e) => {
                    e.memberAdmin = 0;
                    this.checkBox['e'+e.id] = e
                });
            }
            // 最多选中
            this.lengthItem = options.checkBox.length;
            this.length = options.length;
            // 绑定回调方法
            this.success = options.success;
            // 监听滚动
            document.getElementsByClassName('ivu-table-body')[0].addEventListener('scroll', this.scroll);
            // 获取成员列表
            this.searchMember();
        },
        // 监听滚动
        scroll(e) {
            // 距离底部50px时加载一次
            let bottomOfWindow = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <= 50;
            if (bottomOfWindow && !this.noMore && !this.loading) {
                this.loading = true;
                this.page += 1;
                // 成员列表
                this.getMemberList();
            }
        },
        // 确定
        confirm() {
            this.closeModal();
            let array = [];
            Object.keys(this.checkBox).forEach((e) => {
                if (this.checkBox[e]) {
                    array.push(this.checkBox[e])
                }
            });
            this.success(array);
        },
        // 关闭弹窗
        closeModal() {
            this.modal = false;
            // 滚动加载更多
            document.getElementsByClassName('ivu-table-body')[0].removeEventListener("scroll",this.scroll);
        },
        // 获取成员列表
        getMemberList() {
            this.loading = true;
            let params = {
                memberType: 0,
                pageNum: this.page,
                pageSize: this.pageSize,
                name: this.search
            };
            this.$api.schoolMine.member(params).then((res) => {
                res.data.list.forEach((e) => {
                    if (this.checkBox['e'+e.id]) {
                        e.checkBox = true;
                    } else {
                        e.checkBox = false;
                    }
                });
                this.tableData = this.tableData.concat(res.data.list);
                this.count = res.data.total;
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        // 搜索成员
        searchMember() {
            this.page = 1;
            this.tableData = [];
            document.getElementsByClassName('ivu-table-body')[0].scrollTop = 0;
            this.getMemberList();
        },
        // memberSearch() {
        //     this.loading = true;
        //     let params = {
        //         memberType: 0,
        //         pageNum: this.page,
        //         pageSize: this.pageSize,
        //         name: this.search
        //     };
        //     this.$api.schoolMine.memberSearch(params).then((res) => {
        //         res.data.list.forEach((e) => {
        //             e.checkBox = false;
        //         });
        //         this.tableData = res.data.list;
        //         this.count = res.data.total;
        //         this.loading = false;
        //     }).catch(() => {
        //         this.loading = false;
        //     })
        // },
        // 点击表格
        clickTable(e,index) {
            // 判断单选
            if (!this.select) {
                this.tableData.forEach((e)=>{
                    //先取消所有对象的勾选，checkBox设置为false
                    this.$set(e,'checkBox',false)
                });
            }
            if (this.length && this.lengthItem >= this.length && !this.tableData[index].checkBox) {
                this.lengthModal = true
            } else {
                this.tableData[index].checkBox = !this.tableData[index].checkBox;
                this.lengthItem = 0;
                // 将数据储存起来
                this.tableData.forEach((e) => {
                    if (e.checkBox) {
                        let data = {
                            id: e.id,
                            memberAdmin: 0,
                            memberName: e.memberName,
                            memberType: e.memberType
                        };
                        this.checkBox['e'+e.id] = data;
                        // 增加选中数量
                        this.lengthItem += 1;
                    } else {
                        this.checkBox['e'+e.id] = false
                    }
                });
            }
        },
        // 添加成员
        addMember() {
            this.addMemberModal = true;
        },
        handleModalClose() {
            this.addMemberModal = false;
            this.getMemberList();
        }
    }
}
