
import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const plugin = {
    // 获取目录列表
    getPluginTree() {
        return axios.get(`${base.saasAdminRq}/directory/tree`);
    },
     // 获取评论列表
     getCommentList(params) {
        return axios.get(`${base.saasAdminRq}/contentcomments/getContentcomment?documentId=${params.documentId}&phone=${params.phone}`);
    },
    // 添加评论
    addComment(params) {
        return axios.post(`${base.saasAdminRq}/contentcomments/addContentcomment`, params);
    },
    // 点赞评论
    likeComment(params) {
        return axios.get(`${base.saasAdminRq}/contentcomments/addLike?documentId=${params.documentId}&phone=${params.phone}&id=${params.id}`);
    },
    // 插件商城首页
    getTreeData() {
        return axios.get(`${base.saasRq}/store/application/store/category`);
    },
    // 获取插件商城首页列表
    getHomeList(params) {
        return axios.get(`${base.saasRq}/store/application/store/page?industry=${params.industry}&keyword=${params.keyword}&limit=${params.limit}&purpose=${params.purpose}&size=${params.size}&sort=${params.sort}`);
    },
    // 应用详情
    getYDetails(id) {
        return axios.get(`${base.saasRq}/store/application/store/${id}`);
    },
    // 获得企业列表
    getCom(params) {
        return axios.post(`${base.saasRq}/users/ssoCompanies`, params);
    },
    // 下单
    placeOrder(params) {
        return axios.post(`${base.saasRq}/store/application/order/placeOrder`, params);
    },
    // 支付
    payOrder(params) {
        return axios.post(`${base.saasRq}/store/application/order/pay`, params);
    },
    //支付状态
    wxNativePayCallbackReq(params){
		return axios.get(`${base.saasRq}/store/application/order/payResult`,{ params });
	},

}

export default plugin;