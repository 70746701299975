// 资源管理
import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例
const resource = {
    // 发布资源
    addResource(params){
        return axios.post(`${base.rq}api/center/resource/addResource`,params);
    },
    // 资源详情
    getResourceDetail(id) {
        return axios.get(`${base.rq}api/center/resource/detail/${id}`);
    },
    // 全部资源文档
    getAllResource(params) {
        return axios.get(`${base.rq}api/center/resource/list-by-search`,{params});
    },
    // 我的资源
    getMyResource(params){
        return axios.get(`${base.rq}api/center/resource/getPersonalResourcePage`,{params})
    },
    // 删除资源
    deleteResource(id){
        return axios.post(`${base.rq}api/center/resource/deleteResource/${id}`)
    },
    // 取消发布
    cancelRelease(id){
        return axios.post(`${base.rq}api/center/resource/updateReleaseStatus`,{id})
    },
    // 保存草稿箱
    saveDraft(params){
        return axios.post(`${base.rq}api/center/resource/saveDraft`,params)
    },
    // 获取单个资源详情
    getDetail(id){
        return axios.get(`${base.rq}api/center/resource/getDetail/${id}`,)  
    },
    // 编辑发布
    editRelease(id,params){
        return axios.post(`${base.rq}api/center/resource/${id}/edit`,params)  
    },
    // 资源付费
    payResource(params){
        return axios.post(`${base.rq}api/center/resource/payResource`,params)  
    },
    // // 下载pdf
    // downloadPdf(url){
    //     return axios.get(`${base.rq}api/center/resource/payResource`,params)  
    // }

    

}
export default resource