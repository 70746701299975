// 活动

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const activity = {
    // 精选活动
    selectedActivityList() {
        return axios.get(`${base.rq}api/product/campaign/getChoiceList`);
    },
    // 活动列表
    activityList(params) {
        return axios.get(`${base.rq}api/product/campaign/getHomePageList`, {
            params: params
        });
    },
    // 模版列表
    templateList(params) {
        return axios.get(`${base.rq}api/product/campaign/getTemplateList`, {
            params: params
        });
    },
    // 发布活动
    release(params) {
        return axios.post(`${base.rq}api/product/campaign/publishCampaign`, params);
    },
    // 活动详情
    details(params) {
        return axios.get(`${base.rq}api/product/campaign/getCampaignDetail`, {
            params: params
        });
    },


    //获取活动作品详情
    getProductDetailReq(params) {
        return axios.get(`${base.rq}api/product/campaignProduct/getCampaignProductDetail`, {
            params: params
        });
    },
    //获取活动作品投票列表
    getProductVoteListReq(params) {
        return axios.get(`${base.rq}api/product/campaignProduct/getCampaignProductVoteList`, {
            params: params
        });
    },

    // 上传活动作品
    uploadProductReq(params) {
        return axios.post(`${base.rq}api/product/campaignProduct/uploadProduct`, params);
    },
    
    // 投票
    voteReq(params) {
        return axios.post(`${base.rq}api/product/campaignProduct/voteProduct`, params);
    },

    // 获取编辑详情
    getEditCampaignDetailReq(params) {
        return axios.get(`${base.rq}api/product/campaign/getEditCampaignDetail`, {
            params: params
        });
    },
    
    // 编辑活动
    editCampaignReq(params) {
        return axios.post(`${base.rq}api/product/campaign/editCampaign`, params);
    },

    // 活动保存草稿
    saveDraftReq(params) {
        return axios.post(`${base.rq}api/product/campaign/saveDraft`, params);
    },

    // 活动草稿列表
    getDraftListReq(params) {
        return axios.get(`${base.rq}api/product/campaign/getDraftList`, {
            params: params
        });
    },

     // 活动草稿详情
     getDraftDetailReq(params) {
        return axios.get(`${base.rq}api/product/campaign/getDraftDetail`, {
            params: params
        });
    },
};

export default activity;
