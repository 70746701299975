// 设计平台

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const design = {
    // 工具类接口
    // 获取地区
    areaList(params) {
        return axios.get(`${base.rq}api/tool/area/getAreaList/`, {
            params: params
        });
    },


    // 菜单列表
    menuList(params) {
        return axios.get(`${base.rq}api/platform/menu/getPlatformMenuList/`, {
            params: params
        });
    },
    // 轮播图列表
    bannerList(params) {
        return axios.get(`${base.rq}api/platform/banner/getBannerList`, {
            params: params
        });
    },
	// 服务菜单列表
	getWorkTypeDrop(params) {
	    return axios.get(`${base.rq}api/platform/menu/getWorkTypeDrop/`, {
	        params: params
	    });
	},
    // 精选列表
    featuredList(params) {
        return axios.get(`${base.rq}api/product/home-page/featured-list/`, {
            params: params
        });
    },
    // 首页产品列表
    getProductList(params) {
        return axios.get(`${base.rq}api/product/home-page/product-list/`, {
            params: params
        });
    },
    // 发现设计列表
    getFindList(params) {
        return axios.get(`${base.rq}api/product/platformProduct/list-by-menu/`, {
            params: params
        });
    },
    // 作品详情
    getWorksDetail(params) {
        return axios.get(`${base.rq}api/product/platformProduct/detail/${params}/`);
    },
    // 收藏作品
    collection(params) {
        return axios.post(`${base.rq}api/product/platformProduct/addToFavorites/`, params);
    },
    // 取消收藏作品
    cancelCollection(params) {
        return axios.post(`${base.rq}api/product/platformProduct/removeFromFavorites/`, params);
    },
    // 点赞作品
    toLikes(params) {
        return axios.post(`${base.rq}api/product/platformProduct/addToLikes`, params);
    },
    // 取消点赞作品
    cancelLikes(params) {
        return axios.post(`${base.rq}api/product/platformProduct/dislike/`, params);
    },
    // 关注设计师
    follow(params) {
        return axios.post(`${base.rq}api/platform/focus/focusUser/`, params);
    },
    // 取消关注设计师
    cancelFollow(params) {
        return axios.post(`${base.rq}api/platform/focus/unFocusUser/`, params);
    },
    // 获取评论列表
    getCommentList(params) {
        return axios.get(`${base.rq}api/product/product-comment/list-by-product/`,{
            params: params
        });
    },
    // 添加评论
    addComment(params) {
        return axios.post(`${base.rq}api/product/product-comment/add`, params);
    },
    // 点赞评论
    likeComment(params) {
        return axios.post(`${base.rq}api/product/product-comment/like`, params);
    },
    // 设计类型
    designType(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getDesignTypeDrop`,{
            params: params
        });
    },
    // 产业类型
    industryType(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getIndustryTypeDrop`,{
            params: params
        });
    },
    // 奖项列表
    productAwards(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getAwardList`,{
            params: params
        });
    },
    // 学校下拉列表
    collegeList(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getCollegeList`,{
            params: params
        });
    },
    // 团队下拉列表
    teamList(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getTeamDrop`,{
            params: params
        });
    },
    // 实验室下拉列表
    laboratoryList(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getLabDrop`,{
            params: params
        });
    },
    // 新增作品
    addWorks(params) {
        return axios.post(`${base.rq}api/product/platformProduct/addProduct`,params);
    },
    // 编辑作品详情
    getWorksInfo(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getProductEditDetail`,{
            params: params
        });
    },
    // 作品草稿箱列表
    getDraftsList(params) {
        return axios.get(`${base.rq}api/product/platformProduct/getDraftList`,{
            params: params
        });
    },
    // 作品保存草稿箱
    draftsWorks(params) {
        return axios.post(`${base.rq}api/product/platformProduct/saveDraft`,params);
    },
    // 编辑作品
    editWorks(params) {
        return axios.post(`${base.rq}api/product/platformProduct/editPlatformProduct`,params);
    },
	//获取需求投递列表
	getDemandBidList(params){
		return axios.get(`${base.rq}api/center/demand/getDemandBidList`,{
            params: params
        });
	},
    // 需求类型
    demandType(params) {
        return axios.get(`${base.rq}api/platform/menu/getDemandTypeDrop`,{
            params: params
        });
    },
    // 发布需求
    addDemand(params) {
        return axios.post(`${base.rq}api/center/demand/setDemand`,params);
    },
    
    // 需求中心
    getHomeDemandList(params) {
        return axios.get(`${base.rq}api/center/demand/getHomepageDemandPage`,{
            params: params
        });
    },
    // 获取需求详情
    getDemandDetail(params) {
        return axios.get(`${base.rq}api/center/demand/getHomepageDemandDetail`,{
            params: params
        });
    },
    // 参与投标
    joinBid(params) {
        return axios.post(`${base.rq}api/center/demand/addBid`,params);
    },
    // 获取推荐需求列表
    getDeatilRecommendDemand(params) {
        return axios.get(`${base.rq}api/center/demand/getRecommendDemandList`,{
            params: params
        });
    },
	//需求方通过投标,当状态=3,=4可用
	setBidForPass(params) {
	return axios.post(`${base.rq}api/center/demand/setBidForPass`,params);
	},
    //需求方通过投标,当状态=3,=4可用
	setServicePlan(params) {
        return axios.post(`${base.rq}api/center/demand/setServicePlan`,params);
    },
    //需求方通过投标,当状态=3,=4可用 ID
	setBidForPassID(params) {
        return axios.post(`${base.rq}api/center/demand/setBidForPassed`,params);
        },
	//需求方淘汰投标,当状态=3,=4可用
	setBidForFail(params){
	return axios.post(`${base.rq}api/center/demand/setBidForFail`,params);
	},

	//获取设计师列表
	getDesignerList(params){
		return axios.get(`${base.rq}api/platform/designer/getDesignerList`,{
		params: params
		});
	},
	//获取机构列表
	getCompanyList(params){
		return axios.get(`${base.rq}api/platform/designer/getCompanyList`,{
		params: params
		});
	},
	//获取地区列表
	getRegionDrop(){
		return axios.get(`${base.rq}api/platform/designer/getRegionDrop`);
	},
	//获取学校列表
	getCollegeDrop(params){
		return axios.get(`${base.rq}api/platform/designer/getCollegeDrop`,{
		params: params
		});
	},
	//设计师个人详情作品列表
	designerProductList(params){
		return axios.get(`${base.rq}api/platform/designer/designerProductList`,{
		params: params
		});
	},
	//获取设计师个人详情收藏列表
	designerFavoriteProduct(params){
		return axios.get(`${base.rq}api/platform/designer/designerFavoriteProduct`,{
		params: params
		});
	},
	//获取设计师信息
	getUserDetail(params){
		return axios.get(`${base.rq}api/platform/designer/getUserDetail`,{
		params: params
		});
	},
	//获取个人中心用户资料
	getUserCentCard(params){
		return axios.get(`${base.rq}api/platform/center/getUserCentCard`,{
		params: params
		});
	},
	//消息-未读通知
	unreadNotification(params){
		return axios.get(`${base.rq}api/platform/center/unreadNotification`,{
		params: params
		});
	},
    // 设计师履历
    getDesignerResume(params){
        return axios.get(`${base.rq}api/platform/designer/getDesignerRecord`,{
            params: params
        });
    },

    

    getPatentListReq(params){
        return axios.get(`${base.rq}api/product/patent/getPatentList`,{
            params: params
        });
    },

    //申请专利
    //获取申请人信息

    getApplicantDetailReq(params){
		return axios.get(`${base.rq}api/product/patent/getApplicantDetail`,{ params });
	},

    //专利类型
    getPatentTypeDropReq(){
		return axios.get(`${base.rq}api/product/patent/getPatentTypeDrop`);
	},


    // 申请专利
    applyProductPatentReq(params) {
        return axios.post(`${base.rq}api/product/patent/applyProductPatent`,params);
    },

    // 专利配置详情
    getUpdatePatentDetailReq(params){
		return axios.get(`${base.rq}api/product/patent/getUpdatePatentDetail`,{ params });
	},


    // 专利配置
    setProductPatentReq(params) {
        return axios.post(`${base.rq}api/product/patent/setProductPatent`,params);
    },
 
    // 交易管理列表
    getMinePatentListReq(params){
		return axios.get(`${base.rq}api/product/patent/getMinePatentList`,{ params });
	},

    // 我的订单列表
    mineOrderListReq(params){
		return axios.get(`${base.rq}api/product/productDeal/mineOrderList`,{ params });
	},

    // 我的订单详情
    orderDetailReq(params){
		return axios.get(`${base.rq}api/product/productDeal/orderDetail`,{ params });
	},


     // 下单
     placeOrderReq(params){
		return axios.get(`${base.rq}api/product/productDeal/placeOrder`,{ params });
	},



     // 改价
     alterOrderPriceReq(params) {
        return axios.post(`${base.rq}api/product/productDeal/alterOrderPrice`,params);
    },


    // 作品专利i详情
    getProductPatentDetailReq(params){
		return axios.get(`${base.rq}api/product/patent/getProductPatentDetail`,{ params });
	},

    // 微信支付
    wxPayReq(params) {
        return axios.post(`${base.rq}api/product/productDeal/wxPay`,params);
    },

    //支付状态
    wxNativePayCallbackReq(params){
		return axios.get(`${base.rq}api/product/productDeal/wxNativePayCallback`,{ params });
	},
    // 确认转让
    confirmAlterReq(params) {
        return axios.post(`${base.rq}api/product/productDeal/confirmAlter`,params);
    },

    // 申请专利变更
    applyAlterPatentReq(params) {
        return axios.post(`${base.rq}api/product/productDeal/applyAlterPatent`,params);
    },

    // 设置作品附件
    setProductFileUrlReq(params) {
        return axios.post(`${base.rq}api/product/platformProduct/setProductFileUrl`,params);
    },
    // 项目专家支持详情
    getPatentSupplyDetailReq(params){
		return axios.get(`${base.rq}api/product/productDeal/getPatentSupplyDetail`,{ params });
	},
    // 发起专家支持
    initiateSpecialistApplyReq(params) {
        return axios.post(`${base.rq}api/product/productDeal/initiateSpecialistApply`,params);
    },


    // 专利配置专利列表
    getSelectPatentListReq(params){
		return axios.post(`${base.rq}api/product/patent/getSelectPatentList`,params);
	},


    // 专利配置new
    updateProductPatentReq(params) {
        return axios.post(`${base.rq}api/product/patent/updateProductPatent`,params);
    },

     // 添加专利
     addPatentReq(params) {
        return axios.post(`${base.rq}api/product/patent/addPatent`,params);
    },

     // 法律状态下拉
     getLawStatusDropReq(params){
		return axios.get(`${base.rq}api/product/patent/getLawStatusDrop`,{ params });
	},

    
     // 法律状态下拉
     getSinglePatentDetailReq(params){
		return axios.get(`${base.rq}api/product/patent/getSinglePatentDetail`,{ params });
	},

    // 取消专家支持
    cancelSupplyReq(params){
		return axios.post(`${base.rq}api/product/productDeal/cancelSupply`, params );
	},

    // 取消订单
    cancelProductOrderReq(params){
		return axios.post(`${base.rq}api/product/productDeal/cancelProductOrder`, params );
	},

    // 取消专利申请
    cancelPatentApplyReq(params){
		return axios.post(`${base.rq}api/product/patent/cancelPatentApply`, params );
	},
    
};

export default design;
