export default {
    name: "confirm",
    data() {
        return {
            modal: false,
            success: null,
            title: "",
            text: ""
        }
    },
    methods: {
        // 获取携带的参数
        getParamsItem(options) {
            this.modal = true;
            this.title = options.title;
            this.text = options.text;
            this.success = options.success;
        },
        // 确定
        confirm() {
            this.modal = false;
            this.success();
        }
    }
}
