// 我的

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const mine = {
    // 收藏列表
    collectionList(params) {
        return axios.get(`${base.rq}api/platform/center/getUserFavoriteProductList`, {
            params: params
        });
    },
    // 关注列表
    followList(params) {
        return axios.get(`${base.rq}api/platform/center/getUserFocusList`, {
            params: params
        });
    },
    // 作品列表
    worksList(params) {
        return axios.get(`${base.rq}api/platform/center/getMineProductList`, {
            params: params
        });
    },
    // 删除作品
    removeWorks(params) {
        return axios.post(`${base.rq}api/product/platformProduct/deletePlatformProduct`, params);
    },
    // 更新用户信息
    updateInfo(params) {
        return axios.post(`${base.rq}api/platform/designer/updateDesignerProfile`, params);
    },
    // 服务列表
    serviceList(params) {
        return axios.get(`${base.rq}api/center/work/getPersonalWorkPage`, {
            params: params
        });
    },
    // 获取投递需求列表
    bidDemandList(params) {
        return axios.get(`${base.rq}api/center/demand/getBidDemandPage`, {
            params: params
        });
    },
    // 获取发布需求列表
    personalDemandList(params) {
        return axios.get(`${base.rq}api/center/demand/getPersonalDemandPage`, {
            params: params
        });
    },
    // 获取个人中心需求详情
    personalDemandDetail(params) {
        return axios.get(`${base.rq}api/center/demand/getPersonalDemandDetail`, {
            params: params
        });
    },
     // 甲方确认合作
     personalConfirmPlanl(params) {
        return axios.post(`${base.rq}api/center/demand/confirmPlan`, params);
    },
    // 下载需求合同
    downLoadDemandContract(params) {
        return axios.get(`${base.rq}api/center/demand/getContractDownUrl`, {
            params: params
        });
    },
    // 服务方同意价格
    providerConfirmPrice(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandPass`, params);
    },
    // 服务方拒绝价格
    providerRefusePrice(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandFail`, params);
    },
    // 服务方重制价格
    providerResetPrice(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandMoney`, params);
    },
    // 需求打款
    payStageMoney(params) {
        return axios.post(`${base.rq}api/center/demand/pay`, params);
    },
    // 新街口 需求打款
    payDemandPlan(params) {
        return axios.post(`${base.rq}api/center/demand/payDemandPlan`, params);
    },
    // 需求方解冻订单
    demandUnfreezeOrder(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandOrder`, params);
    },
    // 需求方评价
    demandEvaluate(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandAppraise`, params);
    },
    // 服务方上传附件
    providerUploadFile(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandOrderEnclosure`, params);
    },
    // 新街口 服务方上传附件
    setDemandPlanFile(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandPlanFile`, params);
    },
     // 新街口 甲方确认
     setDemandPlanFinish(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandPlanFinish`, params);
    },
	// 用户的公告/系统通知
	getUserNotification(params) {
	    return axios.get(`${base.rq}api/notification/notification/getUserNotification`,{
	        params: params
	    });
	},
	//消息-未读评论
	unreadComment(params) {
	    return axios.get(`${base.rq}api/platform/center/unreadComment`,{
	        params: params
	    });
	},
    // 终止需求
    demandTerminate(params) {
        return axios.post(`${base.rq}api/center/demand/setDemandFinish`, params);
    },
	//设置服务展示，不展示，删除状态
	setWorkStatus(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkStatus`, params);
	},
	//获取服务方-服务列表分页--我服务的
	getWorkWorkPage(params) {
	    return axios.get(`${base.rq}api/center/work/getWorkWorkPage`,{
	        params: params
	    });
	},
	//获取服务方-服务详情--我服务的详情
	getWorkWorkDetail(params) {
	    return axios.get(`${base.rq}api/center/work/getWorkWorkDetail`,{
	        params: params
	    });
	},
	//获取列表分页--我购买的
	getDemandWorkPage(params) {
	    return axios.get(`${base.rq}api/center/work/getDemandWorkPage`,{
	        params: params
	    });
	},
	//获取我购买的详情
	getDemandWorkDetail(params) {
	    return axios.get(`${base.rq}api/center/work/getDemandWorkDetail`,{
	        params: params
	    });
	},
	// 服务费设置价格,当状态=1可用
	setWorkExtraMoney(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkExtraMoney`, params);
	},
    // 删除需求 发布
    delDemand(params) {
        return axios.post(`${base.rq}api/center/demand/delDemand`, params);
    },
    // 删除需求 投递
    delDemandBid(params) {
        return axios.post(`${base.rq}api/center/demand/delDemandBid`, params);
    },
	//删除我的服务--交易 -状态为7是
	delWorkExtra(params) {
	    return axios.post(`${base.rq}api/center/work/delWorkExtra`, params);
	},
	//需求方不同意价格走重新设定,当状态=2可以
	//删除我的服务--交易 -状态为7是
	setWorkExtraMoneyFail(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkExtraMoneyFail`, params);
	},
	//我-确认收货-进入待评价状态
	setWorkExtraFinish(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkExtraFinish`, params);
	},
	//我-评价状态
	setWorkExtraAppraise(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkExtraAppraise`, params);
	},
	//支付记录
	getOrder(params) {
	    return axios.get(`${base.rq}api/center/work/getOrder`,{
	        params: params
	    });
	},
	//需求方支付
	pay(params){
		return axios.post(`${base.rq}api/center/work/pay`, params);
	},
	//需求方关闭交易,当状态=2可以
	setWorkExtraMoneyClose(params){
		return axios.post(`${base.rq}api/center/work/setWorkExtraMoneyClose`, params);
	}
};

export default mine;
