// 用户管理

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const user = {
    // 获取验证码
    getCode(params) {
        return axios.post(`${base.rq}api/platform/captcha/getCaptcha`, null,{params});
    },
    // 登录
    login(params) {
        // return axios.post(`${base.rq}api/uaa/oauth/token`, null,{params});
        return axios.post(`${base.rq}api/platform/account/passwordLogin`, params);
    },
    // 绑定手机号
    bindPhone(params) {
        return axios.post(`${base.rq}api/platform/account/bindMobile`, params);
    },
    // 验证是否注册
    verification(params) {
        return axios.post(`${base.rq}api/platform/account/checkAccount`, null, {params});
    },
    // 验证码登录
    codeLogin(params) {
        return axios.post(`${base.rq}api/platform/account/captchaLogin`, params);
    },
    // 注册
    register(params) {
        return axios.post(`${base.rq}api/platform/account/registerAccount`,null,{params} );
    },
    // 微信登陆 (获取二维码url)
    wechatLog(params) {
        return axios.post(`${base.rq}api/platform/wx/wechatLogin`, params );
    },
    // 微信登陆回调
    wechatLogCallBack(params) {
        return axios.get(`${base.rq}api/platform/wx/wechatLoginCallback`, {params} );
    },
    // qq登陆（获取二维码url）
    qqLog(params) {
        return axios.get(`${base.rq}api/platform/qq/getAuthCode`, {params} );
    },
    // dd登陆回调
    dingdingLog(params) {
        return axios.post(`${base.rq}api/platform/dd/DDLogin`, params );
    },
    // 绑定qq 回调
    qqLogCallback(params) {
        return axios.post(`${base.rq}api/platform/qq/qqLogin`, params);
    },
    // 第三方登陆统一回调
    bindAccount(params) {
        return axios.post(`${base.rq}api/platform/account/bindAccount`, params );
    },
    // 修改密码
    updatePassword(params) {
        return axios.post(`${base.rq}api/platform/account/forgetPassword`,params );
    },
    // 获取用户信息
    getUserInfo() {
        return axios.get(`${base.rq}api/platform/account/getCurrentUser`);
    },
    // 获取账号绑定信息
    getAccountManageInfo() {
        return axios.get(`${base.rq}api/platform/account/getAccountManageInfo`);
    },
    // 发送绑定手机验证码
    getMobileCodeItem(params) {
        return axios.post(`${base.rq}api/platform/account/checkBindMobile`, params);
    },
    // 绑定手机号
    bindingMobile(params) {
        return axios.post(`${base.rq}api/platform/account/bindMobile`, params);
    },
    // 解绑手机号
    unboundMobile(params) {
        return axios.post(`${base.rq}api/platform/account/unbindMobile`, params);
    },
    // 发送绑定邮箱验证码
    getEmailCode(params) {
        return axios.post(`${base.rq}api/platform/account/checkBindEmail`, params);
    },
    // 绑定邮箱
    bindingEmail(params) {
        return axios.post(`${base.rq}api/platform/account/bindEmail`, params);
    },
    // 解绑邮箱
    unboundEmail(params) {
        return axios.post(`${base.rq}api/platform/account/unbindEmail`, params);
    },
    // 修改密码
    uploadPassword(params) {
        return axios.post(`${base.rq}api/platform/account`, params);
    },
    // 绑定微信
    boundWx(params) {
        return axios.post(`${base.rq}api/platform/account/bindWx`, {
            params: params
        });
    },
    // 绑定微信
    boundWxCallback(params) {
        return axios.get(`${base.rq}api/platform/wx/bindWechatAccountCallBack`, {
            params: params
        });
    },
    // 解绑微信
    unboundWx(params) {
        return axios.post(`${base.rq}api/platform/wx/unbindWxAccount`, params);
    },
    // 绑定钉钉
    boundDdCallback(params) {
        return axios.get(`${base.rq}api/platform/dd/bindDDAccountCallBack`, {
            params: params
        });
    },
    // 解绑钉钉
    unboundDd(params) {
        return axios.post(`${base.rq}api/platform/dd/unbindDDAccount`, params);
    },
    // 解绑qq
    unboundQq(params) {
        return axios.post(`${base.rq}api/platform/qq/unbindQQAccount`, params);
    },
    boundQq(params) {
        return axios.get(`${base.rq}api/platform/qq/bindQQAccount`, { params });
    },
    boundQqCallback(params) {
        return axios.get(`${base.rq}api/platform/qq/bindQQAccountCallBack`, {
            params: params
        } );
    },
    // 个人身份证OCR
    personalIdCardOcr(params) {
        return axios.post(`${base.rq}api/platform/authentication/personalIdCardOcr`, params);
    },
    // 获取个人认证信息
    getUserIdentityInfo() {
        return axios.get(`${base.rq}api/platform/authentication/getUserIdentityInfo`);
    },
    // 获取刷脸二维码
    getPsnAuthentication(params) {
        return axios.post(`${base.rq}api/platform/authentication/psnAuthentication`, params);
    },
    // 获取刷脸结果
    getPsnAuthenticationCallback(params) {
        return axios.post(`${base.rq}api/platform/authentication/psnAuthenticationCallback`, params);
    },
    // 营业执照OCR
    licenseOcr(params) {
        return axios.post(`${base.rq}api/platform/authentication/licenseOcr`, params);
    },
    // 获取企业认证信息
    getCompanyIdentityInfo() {
        return axios.get(`${base.rq}api/platform/authentication/getCompanyIdentityInfo`);
    },
    // 法人身份证OCR
    companyIdCardOcr(params) {
        return axios.post(`${base.rq}api/platform/authentication/companyIdCardOcr`, params);
    },
    // 机构类型列表
    getCompanyTypeDrop(params) {
        return axios.get(`${base.rq}api/platform/menu/getCompanyTypeDrop`, {
            params: params
        } );
    },
    // 法人手机发送验证码
    getMobileCode(params) {
        return axios.post(`${base.rq}api/platform/authentication/legalRepAuthentication`, params);
    },
    // 企业认证
    orgAuthentication(params) {
        return axios.post(`${base.rq}api/platform/authentication/orgAuthentication`, params);
    },
    // 产品及服务
    productList(params) {
        return axios.get(`${base.rq}api/official/official/getOfficialProduct`, {
            params: params
        });
    },
    // 上传图片
    uploadImage(params) {
        return axios.post(`${base.rq}api/file/oss/uploadImage`, params);
    },
    // 用户隐私协议
    privacyPolicy() {
        return axios.get(`${base.rq}api/platform/agreement/getPrivacyPolicy`);
    },
    // 平台服务协议
    serviceAgreement() {
        return axios.get(`${base.rq}api/platform/agreement/getServiceAgreement`);
    },
    // 获取地区列表
    regionList() {
        return axios.get(`${base.rq}api/platform/designer/getRegionDrop`);
    },
    // 获取职业列表
    dropList() {
        return axios.get(`${base.rq}api/platform/designer/getOccupationDrop`);
    },

    // 获取个人资金信息页面
    getPersonalCapitalReq(params) {
        return axios.get(`${base.rq}api/fund/fund/getPersonalFundInfo`,{
            params
        });
    },

    // 获取冻结资金列表分页
    getFreezeCapitalReq(params) {
        return axios.get(`${base.rq}api/fund/fund/getPersonalFundFreezePage`,{
            params
        });
    },

    // 取提现列表分页
    getDrawListReq(params) {
        return axios.get(`${base.rq}api/fund/fund/getPersonalFundWithdrawalPage`,{
            params
        });
    },

    // 提交提现
    uploadDrawReq(params) {
        return axios.post(`${base.rq}api/fund/fund/addWithdrawal`, params);
    },

    // 获取手续费
    getFundWithdrawalRateReq(params) {
        return axios.get(`${base.rq}api/fund/fund/getFundWithdrawalRate`, params);
    },
};

export default user;
