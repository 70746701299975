export default {
    name: "selectPeople",
    data() {
        return {
            modal: false,
            success: null,
            // 表单内容
            people: {
                mobile: "",
                username: ""
            },
            rule: {
                mobile: [
                    { required: true, message: '请输入手机', trigger: 'change' },
                    { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式不正确", trigger: "blur" }
                ],
                username: [
                    { required: true, message: '请输入姓名', trigger: 'change' }
                ],
            },
            // 是否显示搜索内容
            visible: false,
            // 账户列表
            phoneList: []
        }
    },
    methods: {
        // 获取携带的参数
        getParamsItem(options) {
            this.modal = true;
            this.people = {
                mobile: "",
                username: ""
            };
            this.$refs.people.resetFields();
            this.success = options.success;
        },
        // 搜索账号
        remoteMethod() {
            if (this.people.mobile !== '') {
                this.$api.schoolMine.getDesignerByPhone({phone: this.people.mobile}).then((res) => {
                    if (res.data.length > 0) {
                        this.phoneList = res.data;
                        this.visible = true;
                    } else {
                        this.phoneList = [];
                        this.visible = false;
                    }
                })
            } else {
                this.phoneList = [];
                this.visible = false;
            }
        },
        // 账户选择
        getSelect(e) {
            this.people.username = this.phoneList[e].designerName;
            this.people.mobile = this.phoneList[e].mobile;
        },
        // 确定
        confirm() {
            this.$refs.people.validate((valid) => {
                if (valid) {
                    this.modal = false;
                    this.success(JSON.parse(JSON.stringify(this.people)));
                }
            });
        }
    }
}
