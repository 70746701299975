// **api接口的统一出口**

// 用户管理接口
import user from './api/user.js';

// 设计平台
import design from './api/design.js';

// 活动
import activity from './api/activity.js';

// 服务
import service from './api/service.js';

// 我的
import mine from './api/mine.js';

// 高校
import school from './api/school.js'

// 高校我的
import schoolMine from './api/schoolMine.js'


// saas
import saas from './api/saas.js'
import plugin from './api/plugin.js'

//资源
import resource from './api/resource.js';

// 导出接口
export default {
    user,
    design,
    activity,
    service,
    mine,
    school,
    schoolMine,
    saas,
    plugin,
    resource
}
