
import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const saas = {
    // 榕器sso账号校验鉴权
    ssoCheckReq(data) {
        return axios.post(`${base.saasRq}/login/ssoCheck`,
            data
        );
    },

    // 榕器云首页价格列表
    getHomePageServiceListReq(data) {
        return axios.get(`${base.saasAdminRq}/service/getHomePageServiceList`,
            data
        );
    },
    // 新增咨询
    addConsultingInformation(data) {
        return axios.post(`${base.saasAdminRq}/consultingInformation/addConsultingInformation`,
            data
        );
    },

    // 查询专题/应用列表
    getCloudSpecialReq(params) {
        return axios.get(`${base.saasAdminRq}/cloudContent/getCloudSpecial`,
        {params}
        );
    },

    // 查询banner
    getCloudBannerReq(params) {
        return axios.get(`${base.saasAdminRq}/cloudBanner/getCloudBanner`,
        {params}
        );
    },
}

export default saas;