// 服务

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const service = {
    // 服务类型列表
    getTypeList(params) {
        return axios.get(`${base.rq}api/platform/menu/getWorkTypeDrop`, {
            params: params
        });
    },
    // 发布服务
    release(params) {
        return axios.post(`${base.rq}api/center/work/setWork`, params);
    },
	// 草稿箱服务
	setWorkDraft(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkDraft`, params);
	},
    // 服务列表
    serviceList(params) {
        return axios.get(`${base.rq}api/center/work/getHomepageWorkPage`,{
            params: params
        });
    },
    // 服务详情
    serviceDetail(params) {
        return axios.get(`${base.rq}api/center/work/getHomepageWorkDetail`,{
            params: params
        });
    },
    // 收藏作品
    collection(params) {
        return axios.post(`${base.rq}api/center/work/setCollect`, params);
    },
	// 发起购买服务请求
	setWorkExtra(params) {
	    return axios.post(`${base.rq}api/center/work/setWorkExtra`, params);
	},
};

export default service;
