import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};


export default new Router({
    mode: 'history',  
    base: '/',
    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        {
            path:'/saas/invite',
            name: 'invite',
            meta: { title: '邀请用户' },
            component: () => import('@/pages/saas/invite.vue')
        },
        {
            path: '/login',
            name: 'login',
            meta: { title: '登录' },
            component: () => import('@/pages/login/index.vue')
        },
        {
            path: '/logCallback',
            name: 'LoginCallback',
            meta: { title: '' },
            component: () => import('@/pages/logCallback/index.vue')
        },
        {
            path: '/register',
            name: 'register',
            meta: { title: '注册' },
            component: () => import('@/pages/register/index.vue')
        },
        {
            path: '/register-success',
            name: 'registerSuccess',
            meta: { title: '注册成功' },
            component: () => import('@/pages/registerSuccess/index.vue')
        },
        {
            path: '/bind-phone',
            name: 'bindPhone',
            meta: { title: '绑定手机号' },
            component: () => import('@/pages/bindPhoneNumber/index.vue')
        },
        {
            path: '/authentication',
            name: 'authentication',
            meta: { title: '开始认证' },
            component: () => import('@/pages/authentication/index.vue')
        },
        {
            path: '/reset-password',
            name: 'resetPassword',
            meta: { title: '重置密码' },
            component: () => import('@/pages/resetPassword/index.vue')
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/pages/home/index.vue'),
            children: [
                {
                    path: '/agreement',
                    name: 'agreement',
                    meta: { title: '协议' },
                    component: () => import('@/pages/agreement/index.vue')
                },
                {
                    path: '/personal',
                    name: 'personal',
                    meta: { title: '个人认证' },
                    component: () => import('@/pages/personal/index.vue')
                },
                {
                    path: '/enterprise',
                    name: 'enterprise',
                    meta: { title: '企业认证' },
                    component: () => import('@/pages/enterprise/index.vue')
                },
                {
                    path: '/official',
                    name: 'official',
                    meta: { title: '榕器共享智造' },
                    component: () => import('@/pages/official/home/index.vue'),
                    children: [
                        {
                            path: '/index',
                            name: 'index',
                            meta: { title: '榕器共享智造' },
                            component: () => import('@/pages/official/index/index.vue')
                        },
                        {
                            path: '/platform',
                            name: 'platform',
                            meta: { title: '平台及服务' },
                            component: () => import('@/pages/official/platform/index.vue')
                        },
                        {
                            path: '/solution',
                            name: 'solution',
                            meta: { title: '行业解决方案' },
                            component: () => import('@/pages/official/solution/index.vue')
                        },
                        {
                            path: '/integrated',
                            name: 'integrated',
                            meta: { title: '智能制造一体化' },
                            component: () => import('@/pages/official/integrated/index.vue')
                        },
                        {
                            path: '/channel',
                            name: 'channel',
                            meta: { title: '展示和营销渠道' },
                            component: () => import('@/pages/official/channel/index.vue')
                        },
                        {
                            path: '/ecology',
                            name: 'ecology',
                            meta: { title: '生态合作' },
                            component: () => import('@/pages/official/ecology/index.vue')
                        },
                        {
                            path: '/about',
                            name: 'about',
                            meta: { title: '关于榕器' },
                            component: () => import('@/pages/official/about/index.vue')
                        }
                    ]
                },
                {
                    path: '/design-box',
                    name: 'design-box',
                    meta: { title: '榕器设计' },
                    component: () => import('@/pages/design/home/index.vue'),
                    children: [
                        {
                            path: '/design',
                            name: 'design',
                            meta: {
                                title: '榕器设计',
                                keepAlive: true
                            },
                            component: () => import('@/pages/design/index/index.vue')
                        },
                        {
                            path: '/find',
                            name: 'find',
                            meta: {
                                title: '发现设计',
                                keepAlive: true
                            },
                            component: () => import('@/pages/design/find/index.vue')
                        },
                        {
                            path: '/activity',
                            name: 'activity',
                            meta: {
                                title: '活动',
                                keepAlive: true
                            },
                            component: () => import('@/pages/design/activity/index.vue')
                        },
                        {
                            path: '/service',
                            name: 'service',
                            meta: { 
                                title: '找服务',
                                keepAlive: true 
                            },
                            component: () => import('@/pages/design/service/index.vue')
                        },
                        {
                            path: '/demand',
                            name: 'demand',
                            meta: { 
                                title: '需求中心',
                                keepAlive: true
                            },
                            component: () => import('@/pages/design/demand/index.vue')
                        },
						{
						    path: '/designer',
						    name: 'designer',
						    meta: { title: '设计师' },
						    component: () => import('@/pages/design/designer/index/index.vue')
						},
						{
						    path: '/designer-detail/:detail_id',
						    name: 'designerDetail',
						    meta: { title: '设计师详情' },
						    component: () => import('@/pages/design/designer/detail/index.vue'),
						},
						{
						    path: '/mechanism',
						    name: 'mechanism',
						    meta: { title: '机构' },
						    component: () => import('@/pages/design/mechanism/index/index.vue')
						},
						{
						    path: '/mechanism-detail/:mechanism_id',
						    name: 'mechanismDetail',
						    meta: { title: '机构详情' },
						    component: () => import('@/pages/design/mechanism/detail/index.vue'),
						},
                        {
                            path: '/colleges',
                            name: 'colleges',
                            meta: { title: '高校' },
                            component: () => import('@/pages/design/colleges/index/index.vue')
                        },
                        {
                            path: '/colleges-detail/:college_id',
                            name: 'collegesDetail',
                            meta: { title: '高校详情' },
                            component: () => import('@/pages/design/colleges/detail/home/index.vue'),
                            children: [
                                {
                                    path: '/colleges-index/:college_id',
                                    name: 'collegesIndex',
                                    meta: { title: '高校首页' },
                                    component: () => import('@/pages/design/colleges/detail/index/index.vue'),
                                },
                                {
                                    path: '/colleges-notice/:college_id',
                                    name: 'collegesNotice',
                                    meta: { title: '高校公告' },
                                    component: () => import('@/pages/design/colleges/detail/notice/index.vue'),
                                },
                                {
                                    path: '/colleges-works/:college_id',
                                    name: 'collegesWorks',
                                    meta: { title: '高校作品' },
                                    component: () => import('@/pages/design/colleges/detail/works/index.vue'),
                                },
                                {
                                    path: '/colleges-activity/:college_id',
                                    name: 'collegesActivity',
                                    meta: { title: '高校活动' },
                                    component: () => import('@/pages/design/colleges/detail/activity/index.vue'),
                                },
                                {
                                    path: '/colleges-laboratory/:college_id',
                                    name: 'collegesLaboratory',
                                    meta: { title: '高校实验室' },
                                    component: () => import('@/pages/design/colleges/detail/laboratory/index.vue'),
                                },
                                {
                                    path: '/colleges-team/:college_id',
                                    name: 'collegesTeam',
                                    meta: { title: '高校团队' },
                                    component: () => import('@/pages/design/colleges/detail/team/index.vue'),
                                },
                                {
                                    path: '/colleges-member/:college_id',
                                    name: 'collegesMember',
                                    meta: { title: '高校成员' },
                                    component: () => import('@/pages/design/colleges/detail/member/index.vue'),
                                }
                            ]
                        },
                        {
                            path: '/colleges-info/:college_id',
                            name: 'collegesInfo',
                            meta: {title: '高校详情'},
                            component: () => import('@/pages/design/colleges/info/home/index.vue'),
                            children: [
                                {
                                    path: '/colleges-info/notice/:college_id/:notice_id',
                                    name: 'collegesInfoNotice',
                                    meta: { title: '公告详情' },
                                    component: () => import('@/pages/design/colleges/info/notice/index.vue'),
                                },
                                {
                                    path: '/colleges-info/member/:college_id/:member_id',
                                    name: 'collegesInfoMember',
                                    meta: { title: '成员详情' },
                                    component: () => import('@/pages/design/colleges/info/member/index.vue'),
                                },
                                {
                                    path: '/colleges-info/laboratory/:college_id/:laboratory_id',
                                    name: 'collegesInfoLaboratory',
                                    meta: { title: '实验室详情' },
                                    component: () => import('@/pages/design/colleges/info/laboratory/index.vue'),
                                },
                                {
                                    path: '/colleges-info/team/:college_id/:team_id',
                                    name: 'collegesInfoTeam',
                                    meta: { title: '团队详情' },
                                    component: () => import('@/pages/design/colleges/info/team/index.vue'),
                                }
                            ]
                        },
                        {
                            path: '/works-detail/:works_id',
                            name: 'worksDetail',
                            meta: { title: '作品详情' },
                            component: () => import('@/pages/design/worksDetail/index.vue')
                        },
                        {
                            path: '/works-detail',
                            name: 'worksPreview',
                            meta: { title: '作品预览' },
                            component: () => import('@/pages/design/worksDetail/index.vue')
                        },
                        {
                            path: '/works-drafts',
                            name: 'worksDrafts',
                            meta: { title: '作品草稿箱' },
                            component: () => import('@/pages/design/worksDrafts/index.vue')
                        },
                        {
                            path: '/works-detail-porductDetail',
                            name: 'productDetail',
                            meta: { title: '作品详情' },
                            component: () => import('@/pages/design/activityDetail/productDetail.vue')
                        },
                        {
                            path: '/activity-detail/:activity_id',
                            name: 'activityDetail',
                            meta: { title: '活动详情' },
                            component: () => import('@/pages/design/activityDetail/index.vue')
                        },
                        {
                            path: '/service-detail/:service_id',
                            name: 'serviceDetail',
                            meta: { title: '服务详情' },
                            component: () => import('@/pages/design/serviceDetail/index.vue')
                        },
                        {
                            path: '/demand-detail/:demand_id',
                            name: 'demandDetail',
                            meta: { title: '需求详情' },
                            component: () => import('@/pages/design/demandDetail/index.vue')
                        },
						{
						    path: '/mine-demandService/:demand_id',
						    name: 'demandDemandService',
						    meta: { title: '需求详情' },
						    component: () => import('@/pages/design/mine/demandDetail/index.vue')
						},
                        {
                            path: '/mine',
                            name: 'mine',
                            meta: { title: '我的' },
                            component: () => import('@/pages/design/mine/index/index.vue'),
                            children: [
                                {
                                    path: '/mine-menu',
                                    name: 'mineMenu',
                                    meta: { title: '我的菜单' },
                                    component: () => import('@/pages/design/mine/menu/home/index.vue'),
                                    children: [
                                        {
                                            path: '/mine-info',
                                            name: 'mineInfo',
                                            meta: { title: '我的资料' },
                                            component: () => import('@/pages/design/mine/info/index/index.vue')
                                        },
                                        {
                                            path: '/mine-account',
                                            name: 'mineAccount',
                                            meta: { title: '账号管理' },
                                            component: () => import('@/pages/design/mine/info/account/index.vue')
                                        },
                                        {
                                            path: '/mine-activity',
                                            name: 'minActivity',
                                            meta: { title: '我的活动' },
                                            component: () => import('@/pages/design/mine/menu/activity/index.vue')
                                        },
                                        {
                                            path: '/mine-order',
                                            name: 'mineOrder',
                                            meta: { title: '我的订单' },
                                            component: () => import('@/pages/design/mine/menu/order/index.vue')
                                        },
                                        {
                                            path: '/works',
                                            name: 'works',
                                            meta: { title: '我的作品' },
                                            component: () => import('@/pages/design/mine/menu/works/index.vue')
                                        },
                                        {
                                            path: '/copyright',
                                            name: 'copyright',
                                            meta: { title: '交易管理' },
                                            component: () => import('@/pages/design/mine/menu/copyright/index.vue')
                                        },
                                        {
                                            path: '/myCopyright',
                                            name: 'myCopyright',
                                            meta: { title: '我的专利' },
                                            component: () => import('@/pages/design/mine/menu/myCopyright/index.vue')
                                        },
                                        {
                                            path: '/collection',
                                            name: 'collection',
                                            meta: { title: '我的收藏' },
                                            component: () => import('@/pages/design/mine/menu/collection/index.vue')
                                        },
                                        {
                                            path: '/project',
                                            name: 'project',
                                            meta: { title: '项目中心' },
                                            component: () => import('@/pages/design/mine/menu/project/index.vue')
                                        },
                                        {
                                            path: '/mine-demand',
                                            name: 'mineDemand',
                                            meta: { title: '我的需求' },
                                            component: () => import('@/pages/design/mine/menu/demand/index.vue'),
                                        },
                                        {
                                            path: '/mine-service',
                                            name: 'mineService',
                                            meta: { title: '我的服务' },
                                            component: () => import('@/pages/design/mine/menu/service/index.vue')
                                        },
                                        {
                                            path: '/mine-resource',
                                            name: 'mineResource',
                                            meta: { title: '我的资源' },
                                            component: () => import('@/pages/design/mine/menu/resource/index.vue')
                                        },
                                        {
                                            path: '/news',
                                            name: 'news',
                                            meta: { title: '消息' },
                                            component: () => import('@/pages/design/mine/menu/news/index.vue')
                                        },
                                        {
                                            path: '/capital',
                                            name: 'capital',
                                            meta: { title: '账户资金' },
                                            component: () => import('@/pages/design/mine/menu/capital/index.vue')
                                        },
                                        {
                                            path: '/follow',
                                            name: 'follow',
                                            meta: { title: '我的关注' },
                                            component: () => import('@/pages/design/mine/menu/follow/index.vue')
                                        },
                                        {
                                            path: '/fans',
                                            name: 'fans',
                                            meta: { title: '粉丝' },
                                            component: () => import('@/pages/design/mine/menu/fans/index.vue')
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: '/mine-colleges',
                            name: 'mineColleges',
                            meta: { title: '我的高校' },
                            component: () => import('@/pages/design/mineColleges/index/index.vue'),
                            children: [
                                {
                                    path: '/mine-colleges-menu',
                                    name: 'mineCollegesMenu',
                                    meta: { title: '我的高校菜单' },
                                    component: () => import('@/pages/design/mineColleges/menu/home/index.vue'),
                                    children: [
                                        {
                                            path: '/colleges/mine-info',
                                            name: 'collegesMineInfo',
                                            meta: { title: '我的资料' },
                                            component: () => import('@/pages/design/mine/info/index/index.vue')
                                        },
                                        {
                                            path: '/colleges/mine-account',
                                            name: 'collegesMineAccount',
                                            meta: { title: '账号管理' },
                                            component: () => import('@/pages/design/mine/info/account/index.vue')
                                        },
                                        {
                                            path: '/colleges/info',
                                            name: 'mineCollegesInfo',
                                            meta: { title: '高校资料' },
                                            component: () => import('@/pages/design/mineColleges/menu/info/index.vue'),
                                        },
                                        {
                                            path: '/colleges/index',
                                            name: 'mineCollegesIndex',
                                            meta: { title: '高校首页' },
                                            component: () => import('@/pages/design/mineColleges/menu/index/index.vue')
                                        },
                                        {
                                            path: '/colleges/notice',
                                            name: 'mineCollegesNotice',
                                            meta: { title: '公告' },
                                            component: () => import('@/pages/design/mineColleges/menu/notice/index.vue')
                                        },
                                        {
                                            path: '/colleges/notice/operation',
                                            name: 'mineCollegesNoticeOperation',
                                            meta: { title: '新增公告' },
                                            component: () => import('@/pages/design/mineColleges/menu/noticeOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/notice/operation/:notice_id',
                                            name: 'mineCollegesNoticeOperation2',
                                            meta: { title: '编辑公告' },
                                            component: () => import('@/pages/design/mineColleges/menu/noticeOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/works',
                                            name: 'mineCollegesWorks',
                                            meta: { title: '高校作品' },
                                            component: () => import('@/pages/design/mine/menu/works/index.vue')
                                        },
                                        {
                                            path: '/colleges/activity',
                                            name: 'mineCollegesActivity',
                                            meta: { title: '活动' },
                                            component: () => import('@/pages/design/mineColleges/menu/activity/index.vue')
                                        },
                                        {
                                            path: '/colleges/laboratory',
                                            name: 'mineCollegesLaboratory',
                                            meta: { title: '实验室管理' },
                                            component: () => import('@/pages/design/mineColleges/menu/laboratory/index.vue')
                                        },
                                        {
                                            path: '/colleges/laboratory/operation',
                                            name: 'mineCollegesLaboratoryOperation',
                                            meta: { title: '新建实验室' },
                                            component: () => import('@/pages/design/mineColleges/menu/laboratoryOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/laboratory/operation/:laboratory_id',
                                            name: 'mineCollegesLaboratoryOperation2',
                                            meta: { title: '编辑实验室' },
                                            component: () => import('@/pages/design/mineColleges/menu/laboratoryOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/team',
                                            name: 'mineCollegesTeam',
                                            meta: { title: '团队管理' },
                                            component: () => import('@/pages/design/mineColleges/menu/team/index.vue')
                                        },
                                        {
                                            path: '/colleges/team/operation',
                                            name: 'mineCollegesTeamOperation',
                                            meta: { title: '新建团队' },
                                            component: () => import('@/pages/design/mineColleges/menu/teamOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/team/operation/:team_id',
                                            name: 'mineCollegesTeamOperation2',
                                            meta: { title: '编辑团队' },
                                            component: () => import('@/pages/design/mineColleges/menu/teamOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/member',
                                            name: 'mineCollegesMember',
                                            meta: { title: '成员管理' },
                                            component: () => import('@/pages/design/mineColleges/menu/member/index.vue')
                                        },
                                        {
                                            path: '/colleges/member/operation',
                                            name: 'mineCollegesMemberOperation',
                                            meta: { title: '新增成员' },
                                            component: () => import('@/pages/design/mineColleges/menu/memberOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/member/operation/:member_id',
                                            name: 'mineCollegesMemberOperation2',
                                            meta: { title: '编辑成员' },
                                            component: () => import('@/pages/design/mineColleges/menu/memberOperation/index.vue')
                                        },
                                        {
                                            path: '/colleges/capital',
                                            name: 'mineCollegesCapital',
                                            meta: { title: '账户管理' },
                                            component: () => import('@/pages/design/mineColleges/menu/capital/index.vue')
                                        },
                                        {
                                            path: '/colleges/fans',
                                            name: 'collegesFans',
                                            meta: { title: '粉丝' },
                                            component: () => import('@/pages/design/mine/menu/fans/index.vue')
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: '/works-release',
                            name: 'worksRelease',
                            meta: {
                                title: '发布作品',
                                keepAlive: true
                            },
                            component: () => import('@/pages/design/worksRelease/index.vue')
                        },
                        {
                            path: '/works-release/:works_id',
                            name: 'worksReleaseEdit',
                            meta: {
                                title: '编辑作品',
                                keepAlive: true
                            },
                            component: () => import('@/pages/design/worksRelease/index.vue')
                        },
                        {
                            path: '/set-patent',
                            name: 'setPatent',
                            meta: {
                                title: '知识产权配置',
                                // keepAlive: true
                            },
                            component: () => import('@/pages/design/patent/setPatent/index.vue')
                        },
                        {
                            path: '/register-patent',
                            name: 'registerPatent',
                            meta: {
                                title: '专利登记',
                                // keepAlive: true
                            },
                            component: () => import('@/pages/design/patent/registerPatent/index.vue')
                        },
                        {
                            path: '/activity-release',
                            name: 'activityRelease',
                            meta: { title: '发布活动' },
                            component: () => import('@/pages/design/activityRelease/index.vue')
                        },
                        {
                            path: '/service-release',
                            name: 'serviceRelease',
                            meta: { title: '发布服务' },
                            component: () => import('@/pages/design/serviceRelease/index.vue')
                        },
						{
						    path: '/service-release/:release_id',
						    name: 'serviceReleaseEdit',
						    meta: { title: '编辑服务' },
					        component: () => import('@/pages/design/serviceRelease/index.vue')
						},
                        {
                            path: '/demand-release',
                            name: 'demandRelease',
                            meta: { title: '发布需求' },
                            component: () => import('@/pages/design/demandRelease/index.vue')
                        },
                        {
                            path: '/resource-release',
                            name: 'resourceRelease',
                            meta: { title: '发布资源' },
                            component: () => import('@/pages/design/resourceRelease/index.vue')
                        },
                        {
                            path: '/resource-edit',
                            name: 'resourceEdit',
                            meta: { title: '编辑资源' },
                            component: () => import('@/pages/design/resourceEdit/index.vue')
                        },
                        {
                            path: '/resource-statistics',
                            name: 'resourceStatistics',
                            meta: { title: '资源统计' },
                            component: () => import('@/pages/design/resourceStatistics/index.vue')
                        },
                        {
                            path: '/order-detail',
                            name: 'orderdetail',
                            meta: { title: '交易详情' },
                            component: () => import('@/pages/design/mine/menu/order/orderDetail/index.vue')
                        },
                        {
                            path: '/specialist-detail',
                            name: 'specialistDetail',
                            meta: { title: '项目专家支持' },
                            component: () => import('@/pages/design/worksDetail/specialist/detail.vue')
                        },
                        {
                            path: '/create-patent',
                            name: 'cratePatent',
                            meta: { title: '新增专利' },
                            component: () => import('@/pages/design/mine/menu/myCopyright/create.vue')
                        },
                        {
                            path: '/copyright-detail',
                            name: 'copyrightDetail',
                            meta: { title: '专利详情' },
                            component: () => import('@/pages/design/mine/menu/copyright/detail.vue')
                        },
                    ]
                },
                {
                    path: '/saas',
                    name: 'saas',
                    meta: { title: '榕器云' },
                    component: () => import('@/pages/saas/index.vue'),
                    redirect:'/saas/index',
                    children: [{
                        path: '/saas/index',
                        name: 'saasIndex',
                        meta: { title: '榕器云' },
                        component: () => import('@/pages/saas/home.vue'),
                    },
                    {
                        path: '/saas/plugin',
                        name: 'saasPlugin',
                        meta: { title: '插件商城' },
                        component: () => import('@/pages/saas/plugin.vue'),
                    },
                    {
                        path: '/saas/designer',
                        name: 'saasDesigner',
                        meta: { title: '开发者文档' },
                        component: () => import('@/pages/saas/designer.vue'),
                    },
                    {
                        path: '/saas/application',
                        name: 'saasApplication',
                        meta: { title: '应用场景' },
                        component: () => import('@/pages/saas/application.vue'),
                    },
                    {
                        path: '/saas/price',
                        name: 'saasPrice',
                        meta: { title: '价格' },
                        component: () => import('@/pages/saas/price.vue'),
                    },
                    {
                        path: '/saas/pluginDetailsy',
                        name: 'pluginDetailsY',
                        meta: { title: '插件商城' },
                        component: () => import('@/pages/saas/pluginDetailsy.vue'),
                    },
                    ],
                },
                {
                    path: '/database',
                    name: 'database',
                    meta: { title: '产业产品数据库' },
                    component: () => import('@/pages/database/index.vue'),
                },
                {
                    path: '/searchResult',
                    name: 'searchResult',
                    meta: { title: '搜索结果' },
                    component: () => import('@/pages/searchResult/index.vue'),
                },
                {
                    path: '/documentDetail',
                    name: 'documentDetail',
                    meta: { title: '结果详情' },
                    component: () => import('@/pages/documentDetail/index.vue'),
                },
            ]
        }
    ]
})
