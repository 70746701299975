// **axios封装**
import axios from 'axios';
import router from '../router';
import store from '../store';
import {Message} from 'view-design';
import base from './base'
// 退出登陆
const loginOut = () => {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("userInfo");
    store.commit("changeUser",null);
    setTimeout(() => {
        router.push({path: '/login'});
    },2000);
};

// // 不想在每次请求都设置的话，可以集中设置下
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';


// 请求失败后的错误统一处理
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        case 400:
            Message.error(other.message|| other.error_description || '参数错误');
            break;
        case 401:
            if (other.status === 400) {
                // 未注册（猜测）
                Message.error("该手机号暂未注册榕器");
            } else {
                Message.error('登陆失效，即将跳转登录');
                loginOut()
            }
            break;
        case 403:
            // 登录过期（token过期）
            Message.error('登录过期，请重新登录');
            loginOut();
            break;
        case 404:
            Message.error('请求的资源不存在');
            break;
        default:
            Message.error('网络异常，请稍后再试');
    }
};

// 跨域访问需要发送cookie
// axios.defaults.withCredentials = true

// 创建axios实例
let instance = axios.create({
    // 超时时间 单位是ms，这里设置了3min的超时时间
    timeout: 3 * 60 * 1000
});

// 请求拦截器
instance.interceptors.request.use(config => {
        // config.header = {
        //     // Authorization: "",
        //     // "Content-Type": "content-type=application/x-www-from-urlencoded", //配置请求头
        //     Connection: "keep-alive"
        // };
        // 请求头添加登陆信息
        let access_token = (localStorage.getItem("access_token") === undefined || localStorage.getItem("access_token") === 'undefined') ? '' : localStorage.getItem("access_token");
        console.log(config,'consif')
        config.headers["Content-Type"] = "application/json;charset=utf-8";
        config.headers["Access-Control-Allow-Origin"] = "*";
        
        // if(!config.url.includes(base.saasRq)) {
            if (access_token) {
                config.headers["Authorization"] = "bearer "+access_token;
            } else {
                config.headers["Authorization"] = "Basic YnJvd3Nlcjo=";
            }
        // }
        return config;
    },
    error => {});

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    (res) => {
    const {config :{url}} = res;
    const whiteList = ['api/product/productDeal/wxNativePayCallback']
    const flag = !!whiteList.find(el=>url.includes(el))
    if (res.status === 200) {
            if (res.data.code === 10000 || res.data.access_token) {
                // 请求成功
                return Promise.resolve(res.data)
            } else {
                // 义务逻辑错误
                !flag && Message.error(res.data.message || res.data.msg || '错误');
                return Promise.reject(res.data);
            }
        } else {
            !flag && Message.error(res.data.message  || res.data.msg || '错误');
            return Promise.reject(res.data)
        }
    },
    // 请求失败
    (error) => {
        // 获取异常参数
        const {response} = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data);
            return Promise.reject(response.data);
        } else {
            // 处理断网的情况
            if (!window.navigator.onLine) {
                Message.error("网络连接异常");
            } else {
                Message.error("榕器服务器异常");
            }
            return Promise.reject('错误');
        }
    });

export default instance;
