<template>
    <router-view v-if="isRouterAlive" />
</template>

<script>
    export default {
        name: 'App',
        provide() {
            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true
            }
        },
        methods: {
            reload() {
                this.isRouterAlive = false
                this.$nextTick(function(){
                    this.isRouterAlive=true
                })
            }
        },
        mounted() {
            // 提示全局的配置
            this.$Message.config({
                duration: 3
            });
        }
    }
</script>
<style>
    /*初始化样式*/
    @import "./assets/css/initial.css";
    /*公共样式*/
    @import "./assets/css/public.css";
    /*富文本样式*/
    @import "./assets/editor/index.css";
</style>
<style lang="less">
    /*全局样式*/
    @import "./assets/css/base.less";
</style>