import draggable from "vuedraggable";
export default {
    name: "uploadImg",
    data() {
        return {
            // 上传组件的地址
            action: "",
            // 上传组件的headers
            headers: {},
            // 默认上传的列表
            fileList: [],
            // 上传组件的列表
            uploadList: [],
            // 上传的图片列表
            imgList: [],
            // 是否允许拖拽排序
            dragDisabled: false,
            // 只触发一次
            flag: true
        }
    },
    components: {
        draggable
    },
    props: [
        // 可上传的数量
        "length",
        // 是否支持拖拽
        "drag",
        // 限制删除文件的大小，需传1024的倍数
        "size",
        // 宽度
        "width",
        // 高度
        "height",
        // 组件背景
        "background",
        // 字体颜色
        "color",
        // 预加载已上传的图片
        "url"
    ],
    watch: {
        url(newVal) {
            // 防止url变化重复组件修改数据
            if (this.flag) {
                this.flag = false;
                if (newVal) {
                    if (Array.isArray(newVal)) {
                        this.fileList = newVal.map((e)=>({url:e, name: "1.png"}));
                        this.imgList = newVal;
                    } else {
                        this.fileList = [newVal].map((e)=>({url:e, name: "1.png"}));
                        this.imgList = [newVal];
                    }
                } else {
                    this.fileList = [];
                    this.imgList = [];
                }
                this.$nextTick(() => {
                    // 重新绑定上传组件列表
                    this.uploadList = this.$refs.upload.fileList;
                })
            }
        }
    },
    mounted() {
        // 上传组件的地址
        this.action = this.root.rq + "api/file/oss/uploadImage";
        // 上传组件的headers
        this.headers = {
            Authorization: (localStorage.getItem("access_token") === undefined || localStorage.getItem("access_token") === 'undefined') ? '' : "bearer "+localStorage.getItem("access_token")
        };
        // 绑定上传组件
        this.uploadList = this.$refs.upload.fileList;
        // 是否可以拖拽排序
        if (this.drag === '' || this.drag === true) {
            this.dragDisabled = false;
        } else {
            this.dragDisabled = true;
        }
    },
    methods: {
        //禁止拖动到id为1的对象
        onMove(e){
            if (e.relatedContext.index > this.uploadList.length - 1) {
                return false
            } else {
                return true
            }
        },
        // 拖拽结束
        onEnd() {
            // 重新绑定上传组件列表
            this.$refs.upload.fileList = this.uploadList.map(el=>({url:el.url,name:el.name,status:'finished'}));
            this.uploadList = this.$refs.upload.fileList;
            // 重新更新组件的参数状态
            let array = [];
            this.uploadList.forEach((c) => {
                array.push(c.url)
            });
            // 用户打开图片展示
            this.imgList = array;
            this.$emit("onChange",this.imgList);
        },
        // 上传封面之前
        beforeUpload() {
            // 只能上传一张
            if (this.length && (this.uploadList.length >= this.length)) {
                this.$Message.warning('限制只能上传'+this.length+'张图片!');
                return false;
            }
        },
        // 上传图片失败
        imageError(file) {
            this.$Message.warning('您选择的 “'+file.name+'” 文件格式不正确，请重新上传!');
        },
        // 上传超出大小提示
        imageMaxSize(file) {
            this.$Message.warning('您选择的图片 “'+file.name+'” 超出了大小限制，请重新上传!');
        },
        // 图片上传成功
        imageSuccess(res, file) {
            if (res.code === 10000) {
                file.url = res.data.url;
                let array = [];
                this.uploadList.forEach((c) => {
                    array.push(c.url)
                });
                // 用户打开图片展示
                this.imgList = array;
                this.$emit("onChange",this.imgList)
            } else {
                this.$Message.error(res.msg);
            }
        },
        // 删除图片
        imageRemove(file) {
            const fileList = this.$refs.upload.fileList;
            this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
            let array = [];
            this.uploadList.forEach((c) => {
                array.push(c.url)
            });
            if (array.length > 0) {
                this.imgList = array;
            } else {
                // 如果单张返回
                if (this.length === 1) {
                    this.imgList = [""];
                } else {
                    this.imgList = [];
                }
            }
            this.$emit("onChange",this.imgList)
        },
    }
}
