// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

// import ElementUI from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入View UI框架
import ViewUI from 'view-design';
// import locale from 'view-design/dist/locale/en-US';
// Vue.use(ViewUI, { locale });
Vue.use(ViewUI);
import './assets/iview/index.css';

// 单独引入element图片查看
import { Image, Skeleton, SkeletonItem, Input, Select, Button, Option,Dialog } from 'element-ui'
Vue.component(Option.name, Option);
Vue.component(Button.name, Button);
Vue.component(Select.name, Select);
Vue.component(Input.name, Input);
Vue.component(Image.name, Image);
Vue.component(Skeleton.name, Skeleton);
Vue.component(SkeletonItem.name, SkeletonItem);
Vue.component(Dialog.name, Dialog);
import 'element-ui/lib/theme-chalk/button.css';
import 'element-ui/lib/theme-chalk/option.css';
import 'element-ui/lib/theme-chalk/input.css';
import 'element-ui/lib/theme-chalk/select.css';
import 'element-ui/lib/theme-chalk/icon.css';
import 'element-ui/lib/theme-chalk/image.css';
import 'element-ui/lib/theme-chalk/skeleton.css';
import 'element-ui/lib/theme-chalk/skeleton-item.css';
import 'element-ui/lib/theme-chalk/dialog.css';

import {Dropdown,DropdownMenu,DropdownItem,Card,Divider} from 'element-ui'
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Card);
Vue.use(Divider);
// Vue.use(Dialog);


// 引入vuex
import store from './store/index'

// 引入swiper轮播图
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper);

// 绑定全局变量
import common from './utils/base.js'
Vue.prototype.root = common;

// 导入api接口
import api from './utils/api.js'
Vue.prototype.$api = api;

// 引入过滤器
import filters from './filters';
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

// 阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false;

// 引入缺省图组件(用到的地方比较多，选用全局引入)
import defaultIcon from './components/defaultIcon/index.vue'
Vue.component('defaultIcon', defaultIcon);

// 引入api全局组件
import confirm from './components/confirm/use.js';
import selectMember from './components/selectMember/use.js';
import selectPeople from './components/selectPeople/use.js';
Vue.use(confirm);
Vue.use(selectMember);
Vue.use(selectPeople);

// 引入导航栏加载条
import NProgress from 'nprogress'
// 引入导航栏加载条资源
import './assets/nprogress/index.css'

// 引入iconfont
import "./assets/iconfont/iconfont.css"

// 路由拦截
router.beforeEach((to, from, next) => {
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 启动加载条
    NProgress.start();
    next();
});
router.afterEach(() => {
    // 关闭加载条
    NProgress.done()
});

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app');
