// **全局变量**
const base = {


    // rq: 'http://192.168.1.2:10030/',   //测试
    // 正式
    // rq: process.env.VUE_APP_NAME=='build'?'http://121.196.239.102/':'http://api.rongqi.com.cn/',//原
    // rq: process.env.VUE_APP_NAME=='build'?'https://api.rongqi.com.cn/':'http://192.168.1.2:10030/',   //打包时-正式
    // rq: process.env.VUE_APP_NAME == 'build' ? 'http://121.196.239.102/' : 'http://192.168.1.2:10030/',   //打包时-测试-2023-1.18
    rq: process.env.VUE_APP_NAME == 'build' ? 'https://plat.rongqi.com.cn/' : 'http://192.168.1.2:10030/',   //打包时-测试-2023-1.18
    // rq: 'http://api.rongqi.com.cn/',
    // saasAdminRq: 'http://192.168.3.22:2829',
    // saasAdminRq: 'http://123.156.225.49/api/admin/',
    // saasRq: 'http://123.156.225.49/api/rongqi',
    // saasRq: 'http://192.168.3.117:2828',
    // saasRq:'http://123.156.225.49/api/rongqi',
    // saasAdminRq:'http://123.156.225.49/api/admin',


    // saasRq:'https://saas.rongqi.com.cn/api/rongqi',  原
    // saasAdminRq:'https://saas.rongqi.com.cn/api/admin',  原
    saasRq: 'https://plats.rongqi.com.cn/api/rongqi',
    saasAdminRq: 'https://plats.rongqi.com.cn/api/admin',

    // rq: 'http://120.27.132.57:10030/',
    // rq:'http://123.156.225.49/api/rongqi/',
    // 本地
    // rq: 'http://192.168.0.188:10030/',
    // rq: 'http://192.168.110.118:10030/',
    // rq: 'http://120.27.132.57:81/',
    // 测试
    // rq: 'http://192.168.110.86:10003/',

    //2022-3-8 测试
    // rq: 'http://49.234.151.141:11001/',
    // 地n址前缀
    api: '',
    api2: 'api/product/',
    // 地址前缀本地代理
    // api: 'rq/'
};

export default base;
