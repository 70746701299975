import uploadImg from "../../../../../components/uploadImg/index.vue"
import store from '@/store/index'
export default {
    name: "mineCollegesMemberOperation",
    data() {
        return {
            member: {
                memberName: "",
                memberAccount: "",
                memberNickname: "",
                memberAvatar: "",
                memberType: 2,
                memberTitleId: "",
                memberLabIdList: [],
                memberTeamIdList: [],
                memberMajor: "",
                memberAttendance: "",
                memberDirection: "",
                memberIntro: "",
                memberPaperList: [{value: ""}],
                memberAwardList: [{value: ""}],
                memberPatentList: [{value: ""}]
            },
            // 表单校验
            rule: {
                memberName: [
                    { required: true, message: '请输入成员姓名', trigger: 'change' }
                ],
                memberAccount: [
                    { required: true, message: '请输入绑定手机号', trigger: 'change' },
                    { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式不正确", trigger: "blur" }
                ],
                memberNickname: [
                    { required: true, message: '请输入成员昵称', trigger: 'change' }
                ],
                memberAvatar: [
                    { required: true, message: '请上传成员照片', trigger: 'change' }
                ],
                memberType: [
                    { validator(rule, value, callback) {
                        if (value === "") {
                            callback("请选择成员角色");
                        } else {
                            callback()
                        }
                    }, required: true, trigger: 'change' }
                ],
                memberTitleId: [
                    { validator(rule, value, callback) {
                        if (value === "") {
                            callback("请选择称谓");
                        } else {
                            callback()
                        }
                    }, required: true, trigger: 'change' }
                ]
            },
            // 称谓列表
            memberTitle: [],
            // 实验室列表
            laboratoryList: [],
            // 团队列表
            teamList: [],
            // 账户列表
            phoneList: [],
            // 是否显示更多
            show: false,
            // 是否显示搜索内容
            visible: false
        }
    },
    components: {
        uploadImg
    },
    props: [
        "type",
        "college"
    ],
    computed: {
        storeUser() {
            return store.state.user || {}
        }
    },
    watch: {
        college() {
            if (store.state.user && store.state.user.designerType === 1) {
                // 获取成员信息
                if (!this.type && this.$route.params.member_id) {
                    this.getMemberInfo()
                }
            }
        }
    },
    mounted() {
        // 获取称谓列表
        this.getMemberTitle();
        if (store.state.user && store.state.user.designerType === 3) {
            // 获取团队/实验室列表
            this.getCollegeLabDrop();
            // 获取成员信息
            if (!this.type && this.$route.params.member_id) {
                this.getMemberInfo()
            }
        } else {
            if (this.college.id) {
                // 获取成员信息
                if (!this.type && this.$route.params.member_id) {
                    this.getMemberInfo()
                }
            }
        }
    },
    methods: {
        // 获取称谓列表
        getMemberTitle() {
            let params = {
                type: this.member.memberType
            };
            this.$api.schoolMine.memberTitle(params).then((res) => {
                this.memberTitle = res.data
            })
        },
        // 获取成员信息
        getMemberInfo() {
            let params = {
                id: this.$route.params.member_id
            };
            if (this.college.id) {
                params.id = this.college.memberId
            }
            this.$api.schoolMine.getMemberInfo(params).then((res) => {
                let memberLabIdList = [];
                let memberTeamIdList = [];
                let memberPaperList = [];
                let memberAwardList = [];
                let memberPatentList = [];
                res.data.memberLabList.forEach((e) => {
                    memberLabIdList.push(e.labId)
                });
                res.data.memberTeamList.forEach((e) => {
                    memberTeamIdList.push(e.labId)
                });
                res.data.memberPaperList.forEach((e) => {
                    memberPaperList.push({
                        value: e
                    })
                });
                res.data.memberAwardList.forEach((e) => {
                    memberAwardList.push({
                        value: e
                    })
                });
                res.data.memberPatentList.forEach((e) => {
                    memberPatentList.push({
                        value: e
                    })
                });
                res.data.memberAccount = res.data.memberBindAccount;
                res.data.memberLabIdList = memberLabIdList;
                res.data.memberTeamIdList = memberTeamIdList;
                res.data.memberPaperList = memberPaperList;
                res.data.memberAwardList = memberAwardList;
                res.data.memberPatentList = memberPatentList;
                this.member = res.data;
                // 获取称谓列表
                this.getMemberTitle();
            })
        },
        // 获取团队/实验室列表
        getCollegeLabDrop() {
            let params = {
                type: 1,
                pageNum: 1,
                pageSize: 999999
            };
            this.$api.schoolMine.collegeLabDrop(params).then((res) => {
                this.laboratoryList = res.data.list
            });
            params = {
                type: 2,
                pageNum: 1,
                pageSize: 999999
            };
            this.$api.schoolMine.collegeLabDrop(params).then((res) => {
                this.teamList = res.data.list
            })
        },
        // 上传成员照片
        uploadChange(res) {
            this.member.memberAvatar = res[0];
        },
        // 搜索账号
        remoteMethod() {
            if (this.member.memberAccount !== '') {
                this.$api.schoolMine.getDesignerByPhone({phone: this.member.memberAccount}).then((res) => {
                    if (res.data.length > 0) {
                        this.phoneList = res.data;
                        this.visible = true;
                    } else {
                        this.phoneList = [];
                        this.visible = false;
                    }
                })
            } else {
                this.phoneList = [];
                this.visible = false;
            }
        },
        // 账户选择
        getSelect(e) {
            this.member.memberNickname = this.phoneList[e].designerName;
            this.member.memberAccount = this.phoneList[e].mobile;
        },
        // 年份选择后
        pickerChange(e) {
            this.member.memberAttendance = e;
        },
        // 保存
        preservation() {
            this.$refs.member.validate((valid) => {
                if (valid) {
                    let memberPaperList = [];
                    this.member.memberPaperList.forEach((e) => {
                        if (e.value) {
                            memberPaperList.push(e.value)
                        }
                    });
                    let memberAwardList = [];
                    this.member.memberAwardList.forEach((e) => {
                        if (e.value) {
                            memberAwardList.push(e.value)
                        }
                    });
                    let memberPatentList = [];
                    this.member.memberPatentList.forEach((e) => {
                        if (e.value) {
                            memberPatentList.push(e.value)
                        }
                    });
                    let params = {
                        memberName: this.member.memberName,
                        memberAccount: this.member.memberAccount,
                        memberNickname: this.member.memberNickname,
                        memberAvatar: this.member.memberAvatar,
                        memberType: this.member.memberType,
                        memberTitleId: this.member.memberTitleId,
                        memberLabIdList: this.member.memberLabIdList,
                        memberTeamIdList: this.member.memberTeamIdList,
                        memberDirection: this.member.memberDirection,
                        memberIntro: this.member.memberIntro,
                        memberPaperList: memberPaperList,
                        memberAwardList: memberAwardList,
                        memberPatentList: memberPatentList
                    };
                    if (this.member.memberType === 1) {
                        params.memberMajor = this.member.memberMajor;
                        params.memberAttendance = this.member.memberAttendance;
                    }
                    if (!this.type && this.$route.params.member_id) {
                        if (this.college.id) {
                            params.id = this.college.memberId
                        } else {
                            params.id = this.$route.params.member_id;
                        }
                        this.$api.schoolMine.editMember(params).then(() => {
                            this.$Message.success("更新成员信息成功");
                            this.$router.go(-1)
                        })
                    } else {
                        this.$api.schoolMine.addMember(params).then(() => {
                            this.$Message.success("新增成员成功");
                            if(this.type === 'add') {
                                this.$emit('close');
                            } else {
                                this.$router.go(-1)
                            }
                        })
                    }
                }
            });
        },
        handleCancel() {
            if(this.type == 'route') {
                this.$router.go(-1)
            }
            this.$emit('close');
        }
    }
}
