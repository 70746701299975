// 高校

import base from '../base.js'; // 导入接口域名列表
import axios from '../http.js'; // 导入http中创建的axios实例

const school = {
    // 高校列表
    list(params) {
        return axios.get(`${base.rq}api/platform/college/searchCollege`, {
            params: params
        });
    },
    // 实验室列表
    laboratoryList(params) {
        return axios.get(`${base.rq}api/platform/college/searchLab`, {
            params: params
        });
    },
    // 高校首页数据
    collegeHome(params) {
        return axios.get(`${base.rq}api/platform/college/collegeHomepage`, {
            params: params
        });
    },
    // 高校详情
    details(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeProfile`, {
            params: params
        });
    },
    // 高校作品
    works(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeProductList`, {
            params: params
        });
    },
    // 高校公告
    notice(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeNoticeList`, {
            params: params
        });
    },
    // 高校公告详情
    noticeDetail(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeNoticeDetail`, {
            params: params
        });
    },
    // 高校活动
    activity(params) {
        return axios.get(`${base.rq}api/product/collegeCampaign/getCollegeCampaign`, {
            params: params
        });
    },
    // 实验室/团队列表
    collegeLabList(params) {
        return axios.get(`${base.rq}api/platform/college/collegeLabList`, {
            params: params
        });
    },
    // 实验室/团队详情
    laboratoryDetail(params) {
        return axios.get(`${base.rq}api/platform/college/getLabDetail`, {
            params: params
        });
    },
    // 成员列表
    member(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeMember`, {
            params: params
        });
    },
    // 成员详情
    memberDetail(params) {
        return axios.get(`${base.rq}api/platform/college/getCollegeMemberDetail`, {
            params: params
        });
    },
};

export default school;
