// 距离当前时间
exports.timeDistance = (time) => {
    // 支持传入10位或13位毫秒数，如 1587367194536,"1587367194"
    // 支持传入日期格式，如 "2020/4/20 15:31:18"
    if (typeof time == "number" || Number(time) === time) {
        if (String(time).length === 10) {
            time = Number(time) * 1000
        } else if (String(time).length === 13) {
            time = Number(time)
        } else {
            return time;
        }
    } else {
        if (typeof time == "string" && time.split(" ").length === 2 && time.split(/[- : \/]/).length === 6) {
            time = new Date(time.replace(/\-/g, '/')).getTime();
        } else {
            return time;
        }
    }
    // 处理之后的time为13位数字格式的毫秒数
    let date_now = new Date();
    let date_time = new Date(time);
    let distance = date_now.getTime() - time;
    let days = parseInt(distance / (1000 * 60 * 60 * 24));
    if (days === 1) {
        return "昨天"
    } else if (days > 1 && days < 4) {
        return days + "天前";
    } else if (days > 3) {
        // 超过3天的，返回日期，如 2018-12-05
        // 如果是今年的，就省去年份，返回 12-05
        let year = date_time.getFullYear();
        let month = date_time.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        let day = date_time.getDate();
        if (day < 10) {
            day = "0" + day;
        }
        if (date_now.getFullYear() === year) {
            return month + "月" + day + "日";
        } else {
            return year + "年" + month + "月" + day + "日";
        }
    }
    let hours = parseInt((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (hours > 0) {
        return hours + "小时前";
    }
    let minutes = parseInt((distance % (1000 * 60 * 60)) / (1000 * 60));
    if (minutes > 0) {
        return minutes + "分钟前";
    }
    return "刚刚";
};

// 处理时间
// date('MM-dd hh:mm')
exports.date = (time, fmt) => {
    let date = new Date(time);
    let o = {
        "y+": date.getUTCFullYear(), //年份
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    return fmt;
};

// 处理数字
exports.num_w = (value) => {
    let realVal = '';
    if (value !== null && !isNaN(value) && value !== '') {
        // 截取当前数据到小数点后两位
        if (value > 10000 * 10000 | value < -10000 * 10000) {
            realVal = parseFloat(value / 10000 / 10000).toFixed(1) + '亿'
        } else if (value > 10000 | value < -10000) {
            realVal = parseFloat(value / 10000).toFixed(1) + 'w'
        } else {
            realVal = value.toLocaleString()
        }
    } else {
        realVal = '--'
    }
    return realVal
};

// map方法封装
exports.map = (value,key) => {
    let realVal = "";
    if (value) {
        realVal = value.map(item => item[key])
    }
    return realVal;
};

// 手机号密文处理
exports.mobile = (value) => {
    let enStr = value.replace(/(\d{3})\d*(\d{4})/,"$1****$2")
    return enStr;
};

// 获取url
exports.getUrl = (key) => {
    let reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    let r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null) return unescape(r[2]); return null; //返回参数值
};

// 修改url (参数为对象 key是参数名，value是值)
exports.setUrl = (value) => {
    let url = window.location.href;
    for (let key in value) {
        if (this.getUrl(key) != null) {
            let re = eval('/(' + key + '=)([^&]*)/gi');
            url = url.replace(re, key + '=' + value[key]);
        } else {
            if (url.indexOf("?") == -1) {
                url = url + '?' + key + '=' + value[key]
            } else {
                url = url + '&' + key + '=' + value[key]
            }
        }
    }
    return url
};

// 补零
exports.filling = (value) => {
    if (value < 10) {
        value = "0" + value;
    }
    return value
};
